@import url(https://rsms.me/inter/inter.css);
.primary-button {
  background-color: #da182b;
  border-color: #da182b;
  color: white;
}
.primary-button .ant-btn {
  position: static;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  box-shadow: none;
}
.primary-button .ant-btn-default:hover,
.primary-button .ant-btn-default:focus {
  background-color: #e6ffee;
  border-color: #8bf7b8;
  color: white;
}
.primary-button .ant-btn:hover,
.primary-button .ant-btn:focus {
  position: static;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  background-color: #e6ffee;
  border-color: #8bf7b8;
  color: white;
}
.ant-btn {
  box-shadow: none !important;
}
.primary-button:hover,
.primary-button:focus,
.primary-button:active,
.primary-button.active {
  text-decoration: none;
  background: #e94437;
}
.primary-button:hover,
.primary-button:focus {
  color: white;
  background-color: #e94437;
  border-color: #e94437;
}
.error-button {
  background-color: #e94437;
  border-color: #e94437;
  color: white;
}
.error-button .ant-btn {
  position: static;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  box-shadow: none;
}
.error-button .ant-btn:active,
.error-button .ant-btn.active {
  background-color: #fff6f0;
  border-color: #ffd3bd;
  color: white;
}
.error-button .ant-btn:hover,
.error-button .ant-btn:focus {
  background-color: #fff6f0;
  border-color: #ffd3bd;
  color: white;
}
.error-button:hover,
.error-button:focus,
.error-button:active,
.error-button.active {
  text-decoration: none;
  background: #fff6f0;
}
.error-button:hover,
.error-button:focus {
  color: white;
  background-color: #fff6f0;
  border-color: #ffd3bd;
}
.warning-button {
  background-color: #fbbc06;
  border-color: #fbbc06;
  color: white;
}
.warning-button .ant-btn {
  position: static;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  box-shadow: none;
}
.warning-button .ant-btn-default:hover,
.warning-button .ant-btn-default:focus {
  background-color: #feffe6;
  border-color: #fffb8f;
  color: white;
}
.warning-button .ant-btn:hover,
.warning-button .ant-btn:focus {
  background-color: #feffe6;
  border-color: #fffb8f;
  color: white;
}
.warning-button:hover,
.warning-button:focus,
.warning-button:active,
.warning-button.active {
  text-decoration: none;
  background: #feffe6;
}
.warning-button:hover,
.warning-button:focus {
  color: white;
  background-color: #feffe6;
  border-color: #fffb8f;
}
.info-button {
  background-color: #02abfe;
  border-color: #02abfe;
  color: white;
}
.info-button .ant-btn {
  position: static;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  box-shadow: none;
}
.info-button .ant-btn-default:hover,
.info-button .ant-btn-default:focus {
  background-color: #8df3fc;
  border-color: #36c9e3;
  color: white;
}
.info-button .ant-btn:hover,
.info-button .ant-btn:focus {
  background-color: #8df3fc;
  border-color: #36c9e3;
  color: white;
}
.info-button:hover,
.info-button:focus,
.info-button:active,
.info-button.active {
  text-decoration: none;
  background: #feffe6;
}
.info-button:hover,
.info-button:focus {
  color: white;
  background-color: #feffe6;
  border-color: #fffb8f;
}
.override .ant-btn {
  position: static;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  box-shadow: none;
}
.override-primary .ant-btn-default:hover,
.override-primary .ant-btn-default:focus {
  background-color: #e6ffee;
  border-color: #8bf7b8;
  color: white;
}
.override-primary .ant-btn:hover,
.override-primary .ant-btn:focus {
  position: static;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  background-color: #e6ffee;
  border-color: #8bf7b8;
  color: white;
}
.override-error .ant-btn:active,
.override-error .ant-btn.active {
  background-color: #fff6f0;
  border-color: #ffd3bd;
  color: white;
}
.override-error .ant-btn:hover,
.override-error .ant-btn:focus {
  background-color: #fff6f0;
  border-color: #ffd3bd;
  color: white;
}
.override-warning .ant-btn-default:hover,
.override-warning .ant-btn-default:focus {
  background-color: #feffe6;
  border-color: #fffb8f;
  color: white;
}
.override-warning .ant-btn:hover,
.override-warning .ant-btn:focus {
  background-color: #feffe6;
  border-color: #fffb8f;
  color: white;
}
.override-info .ant-btn-default:hover,
.override-info .ant-btn-default:focus {
  background-color: #8df3fc;
  border-color: #36c9e3;
  color: white;
}
.override-info .ant-btn:hover,
.override-info .ant-btn:focus {
  background-color: #8df3fc;
  border-color: #36c9e3;
  color: white;
}
.enter_button button {
  width: 100%;
}
.enter_button .label {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #858c96;
}
.outlined_button {
  color: #25A42E;
  border: 1px solid #25A42E;
}
.ant-btn {
  border-radius: 0.33rem;
}

.formik_input,
.formik_input > .ant-select-selection {
  border: none !important;
  border-radius: 0 !important;
  background: none !important;
  font-size: 20px;
  line-height: 28px;
  padding: 0 !important;
  outline: none !important;
  outline-style: none !important;
  box-shadow: none !important;
}
.formik-input__with-suffix .ant-input {
  border: none !important;
  border-radius: 0 !important;
  background: none !important;
  font-size: 20px;
  line-height: 28px;
  outline: none !important;
  outline-style: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  padding-right: 30px !important;
}
.no-outline {
  outline: none !important;
  outline-style: none !important;
  box-shadow: none !important;
}
.password-popover-content p {
  font-size: 14px;
}
.formik-datepicker {
  border-bottom: 1px solid #e7eaed;
}
.formik-datepicker .ant-calendar-picker-input {
  border: none !important;
  border-radius: 0 !important;
  background: none !important;
  font-size: 20px;
  line-height: 28px;
  padding: 0 !important;
}
.inline-flex {
  display: inline-flex;
}
.border-bottom-only {
  border: none !important;
  border-bottom: 1px solid #e7eaed !important;
  border-radius: 0 !important;
  background: none !important;
  font-size: 20px;
  line-height: 28px;
  padding: 0 !important;
  outline: none !important;
  outline-style: none !important;
  box-shadow: none !important;
}
.border-bottom-only:focus-within {
  color: #da182b;
}
.border-bottom-only.ant-input-number-focused {
  background: #FFFFFF !important;
}
.ant-input-number {
  width: 100% !important;
}
.ant-select-selection-item-remove {
  display: flex !important;
  align-items: center;
}
.input-tag {
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
}
.input-tag input {
  border: none;
  width: 100%;
  height: 20px;
}
.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}
.input-tag__tags li {
  align-content: center;
  align-items: center;
  background: #F2F5F7;
  border: 1px solid #D3D9E0;
  border-radius: 4px;
  color: #545B68;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  height: 20px;
}
.input-tag__tags li button {
  align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #333333;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 10px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  transform: rotate(45deg);
  width: 15px;
}
.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
  border: none;
  width: 30%;
  height: 20px;
}

.round_border {
  border-radius: 0.33rem !important;
}
.ant-select-selector {
  border-radius: 0.33rem !important;
  min-height: 2.5rem !important;
}
.input_height {
  height: 2.5rem !important;
}

.formik-field__transition {
  transition-duration: 0.5s;
  transition-timing-function: linear;
}
.form-container {
  max-width: 1140px;
  margin: 0 auto;
}
.formik-field {
  transition-duration: 0.5s;
  transition-timing-function: linear;
}
.formik-field:focus-within {
  border-right-width: 1px !important;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(39, 41, 44, 0.1);
}
.formik-field:focus-within .input-wrapper {
  border-bottom: 1px solid transparent;
}
.formik-field:focus-within .formik-field__input-label {
  color: #da182b;
}
.formik-field__input-label {
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  display: block;
  color: #858C96;
}
.focus-visible {
  border-right-width: 0 !important;
  box-shadow: none !important;
  background: none !important;
}
.formik-field__error {
  margin-top: 4px;
  height: 20px;
}
.formik-field__error__margin-left {
  margin-left: 11px;
}
.formik-field__span {
  color: #858c96;
  text-transform: none;
  font-weight: normal;
}
.checkbox--float-left {
  float: left;
  padding-right: 8px !important;
}
.ant-popover {
  font-size: 14px !important;
}
.input-wrapper {
  border-bottom: 1px solid #e7eaed;
  margin-left: 11px;
}
.input-wrapper_with_error {
  border-bottom: 1px solid #B11C21;
}
.disabled-input-wrapper {
  margin-left: 11px;
}
.disabled-formik-field {
  border: 1px solid #e7eaed;
}
.progress-bar {
  margin-left: 11px;
}
.inline-grid {
  display: inline-block;
}
.ant-select {
  width: 100%;
}
.ant-input {
  padding: 8px;
}
.ant-input-number {
  padding: 4px;
}
.ant-select-selection--multiple {
  padding: 8px;
}
.ant-input-affix-wrapper {
  max-height: 40px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  max-height: 40px !important;
  height: 40px !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 4px;
}
.ant-select-selector {
  padding: 8px;
}
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 30px !important;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  justify-content: center;
  align-items: center;
}
.ant-select-selection--single {
  padding: 8px;
}
.ant-select-selection__rendered {
  padding: 8px;
}
.label-down {
  display: block;
  padding-left: 11px;
}
.ant-upload-picture-card-wrapper {
  display: block !important;
}
.ant-modal-close-x {
  padding-top: 4px;
}
.border_error {
  border: 2px solid #e94437 !important;
}
.border_error.ant-input {
  border: 2px solid #e94437 !important;
}
.border_error.ant-input-number-input-wrap {
  border: 2px solid #e94437 !important;
}
.password_error {
  border: 2px solid #e94437 !important;
}
.select_border_error .ant-select-selection {
  border: 2px solid #e94437 !important;
}

.alert_warning {
  background: #FEFFE6;
  border: 1px solid #D4B106;
}
.alert_warning .ant-alert-icon {
  color: #D4B106 !important;
}
.alert_info {
  background: #D4DDF9;
  border: 1px solid #1B2977;
}
.alert_info .ant-alert-icon {
  color: #1B2977 !important;
}
.alert_error {
  background: #FCE3D3;
  border: 1px solid #B11C21;
}
.alert_error .ant-alert-icon {
  color: #B11C21 !important;
}
.alert_success {
  background: #E4FBD6;
  border: 1px solid #25A42E;
}
.alert_success .ant-alert-icon {
  color: #25A42E !important;
}

.login {
  height: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin-top: 1rem;
}
.background_primary {
  background-color: #da182b;
  height: 100vh;
}
.login_container__content_center {
  max-width: 368px;
}

.custom_card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border-radius: 2px;
  border: 1px solid #e8e8e8;
}
.ant-card {
  border-radius: 2px;
  width: 100%;
  margin-bottom: 1rem !important;
}
.ant-card > .ant-card-head {
  border-bottom: 0px;
}
.ant-card-bordered {
  border: 1px solid #e8e8e8 !important;
}
.ant-card-bordered .ant-card-head {
  border-bottom: 1px solid #f0f0f0;
}
.custom_card__padding {
  padding: 1.875rem 1.875rem;
}
.custom_card .custom_card__content-body {
  padding: 1.875rem 1.875rem;
}
.custom_card__border_bottom {
  border-bottom: 1px solid #F2F5F7;
}
.custom_collapse.ant-collapse {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: none !important;
}
.custom_collapse .ant-collapse-content {
  border-top: none !important;
}
.custom_collapse .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px !important;
}
.custom_collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.custom_collapse .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 0px !important;
  padding-top: 8px !important;
  background-color: inherit !important;
}
.custom_collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #545B68 !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: none !important;
}
.custom_collapse.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}
.custom_collapse .ant-collapse-content {
  color: #545B68 !important;
  border-top: none !important;
  background-color: inherit !important;
}
.custom-collapse-styles {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: none !important;
}
.custom_collapse__border_bottom {
  border-bottom: 1px solid #F2F5F7;
}
.custom_collapse__action:hover {
  cursor: pointer;
}
.custom_collapse__no_space {
  max-height: 100px;
  overflow: hidden;
}
.custom_collapse__no_space .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-bottom: 0px !important;
}
.custom_collapse__no_space:hover {
  overflow-y: scroll;
  visibility: visible;
}
.custom_collapse__no_space p {
  padding: 0;
  margin: 0;
}
.round_corners {
  border-radius: 0.4rem !important;
  box-shadow: 0px 24px 32px rgba(50, 50, 71, 0.08), 0px 16px 16px rgba(50, 50, 71, 0.08);
}
.ant-button {
  box-shadow: 0px 4px 8px rgba(50, 50, 71, 0.06), 0px 4px 4px rgba(50, 50, 71, 0.08);
}
.no_padding .ant-card-body {
  padding: 0 !important;
}
.filter_label {
  color: #858C96;
  font-weight: 400;
  font-size: 16px;
}
.filter_label:hover {
  color: #da182b;
}
.ant-table-row-expand-icon {
  margin-top: 0 !important;
}

.width_10 {
  width: 10%;
}
.width_20 {
  width: 20%;
}
.width_30 {
  width: 30%;
}
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  font-weight: bold !important;
  padding: 4px 4px !important;
}
.report_title {
  text-align: center !important;
}

.notification_error {
  background: #FCE3D3;
  border: 1px solid #B11C21;
}
.notification_error .ant-notification-notice-icon-error {
  color: #B11C21;
}
.notification_success {
  background: #E4FBD6;
  border: 1px solid #25A42E;
}
.notification_success .ant-notification-notice-icon-success {
  color: #25A42E;
}
.notification_info {
  background: #D4DDF9;
  border: 1px solid #1B2977;
}
.notification_info .ant-notification-notice-icon-info {
  color: #1B2977;
}

.change-password-wrapper {
  background: #FFFFFF;
}
.change-password-wrapper .width-100 {
  width: 100%;
}

.header {
  width: 100%;
  clear: both;
  background: #F9FAFC;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  height: 49px;
  z-index: 1;
}
.header .header-logo {
  position: absolute;
  top: 20px;
  left: 40px;
  display: flex;
  flex-direction: row;
}
.header .header-logo .header-divider {
  border-right: 1px solid #D3D9E0;
  margin-left: 16px;
  margin-right: 16px;
  height: 40px;
}
.header .header-right-section {
  position: absolute;
  top: 20px;
  right: 60px;
  display: flex;
  flex-direction: row;
}
.header .header-right-section .header-signin {
  display: flex;
  flex-direction: row;
}
.header .header-right-section .header-signin img {
  -webkit-filter: invert(1);
          filter: invert(1);
  margin-top: 5px;
  margin-right: 5px;
  width: 16px;
  height: 16px;
}
.header .header-right-section .header-user-icon {
  background-color: #da182b;
  display: inline-block !important;
}
.header .header-right-section .header-user-fullName {
  position: relative;
  top: 5px;
  margin-left: 8px;
}
.header .header-right-section .header-menu-icon {
  position: relative;
  margin-left: 9.5px;
  top: 12px;
  width: 13.5px;
  height: 8px;
}
.header-user-icon {
  background-color: #da182b !important;
  justify-content: center;
  align-items: center;
}
.tab-header {
  position: absolute;
  top: 20px;
  right: 250px;
  display: flex;
  flex-direction: row;
}
.grey-link {
  color: #545B68;
}
.personal-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-table-row-expand-icon-cell > button {
  visibility: hidden;
}
.report_title {
  text-align: center !important;
}

.no_hover_styles {
  color: none !important;
  border-bottom: none !important;
}
.header-menu-container {
  background: white;
  border-bottom: 1px solid #e8e8e8;
}
.header_right_section {
  position: absolute !important;
  right: 0;
}
.store_settings_section {
  position: absolute !important;
  right: 8rem;
  font-size: 20px !important;
  cursor: pointer;
}
.ant-menu-horizontal {
  border-bottom: 0px;
}
.ant-menu-horizontal > .ant-menu-item {
  vertical-align: inherit !important;
}
.ant-menu {
  z-index: 10;
}
.dashboard_layout_content {
  background: none;
  box-sizing: border-box;
  margin: 24px 48px;
}
.dashboard_body {
  min-height: calc(100vh - 49px);
}
@media (max-width: 500px) {
  .header_right_section {
    position: relative !important;
  }
  .store_settings_section {
    position: relative !important;
  }
  .store_settings_container {
    width: 100%;
  }
}
.ant-input-number-handler-wrap {
  visibility: hidden;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  background-color: #f0f2f5;
  font-size: 16px;
  line-height: 20px;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.container {
  margin: 0 auto;
  padding: 0 16px;
}
.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: none;
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1300px) {
  .container {
    max-width: 1280px;
  }
}
.w-100 {
  width: 100%;
}
.card {
  background: #FFFFFF;
  border: 1px solid #F2F5F7;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(230, 235, 240, 0.5);
  border-radius: 4px;
}
.form_center {
  padding: 0 160px;
}
.more_actions_icon {
  border: 1px solid #D3D9E0;
  padding: 3px 9px;
  border-radius: 8px;
}
.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.flex_space_between {
  display: flex;
  justify-content: space-between;
}
.flex_space_between__center {
  align-items: center;
}
.flex_space_around {
  display: flex;
  justify-content: space-around;
}
.flex__end {
  justify-content: flex-end;
}
.flex__no_wrap {
  flex-wrap: nowrap;
}
.flex__wrap {
  flex-wrap: wrap;
}
.fleflex__align_center {
  align-items: center;
}
.flex__center {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex {
  display: flex;
}
.cursor_pointer {
  cursor: pointer;
}
.inner-padding {
  padding: 16px 24px;
}
.float-right {
  float: right;
}
.inline {
  display: inline;
}
.inline_block {
  display: inline-block;
}
.right-0 {
  right: 0px;
}
.float-right {
  float: right;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px !important;
}
.font-20 {
  font-size: 20px;
}
.weight-600 {
  font-weight: 600;
}
.font-16 {
  font-size: 16px;
}
.primary-color {
  color: #da182b;
}
.info-color {
  color: #02abfe;
}
.block {
  display: block;
}
.align_center {
  align-items: center;
}
.text_center {
  text-align: center;
}
.ant-pagination-prev {
  font-family: Arial;
  line-height: 32px !important;
}
.ant-layout {
  background: #f0f2f5 !important;
}
.flex {
  display: flex;
}
.flex__center {
  align-items: center;
}
.flex__wrap {
  display: flex;
  flex-wrap: wrap;
}
.break_word {
  word-break: break-word;
}
.flex__direction_column {
  flex-direction: column;
}
.flex__space_between {
  display: flex !important;
  justify-content: space-between;
}
.flex__space_between_centered {
  align-items: center;
}
.flex__wrap {
  flex-wrap: wrap;
}
.flex__space_between_baseline {
  justify-content: space-between;
  align-items: baseline;
}
.flex_baseline {
  align-items: baseline;
}
.fd-col {
  flex-direction: column;
}
.fd-row {
  flex-direction: row;
}
.full_width {
  width: 100% !important;
}
.full-height {
  height: 100%;
  overflow: hidden;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.bottom-0 {
  bottom: 0;
}
.info-primary-color {
  color: #12b1d6;
}
.skyblue {
  color: skyblue;
}
.pointer {
  cursor: pointer;
}
/* Texts */
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-semi-bold {
  font-weight: 600;
}
.font_15 {
  font-size: 15px;
}
.underline {
  text-decoration: underline;
}
.link {
  color: #1B2977;
}
.white_icon {
  color: #FFFFFF;
}
.info_icon {
  color: #1B2977;
}
/* Widths */
.container-50-percent {
  width: 50%;
  margin: 0 auto;
}
.container-75-percent {
  width: 75%;
  margin: 0 auto;
}
.container-100-percent {
  width: 100%;
  margin: 0 auto;
}
.jc-space-around {
  justify-content: space-around;
}
.jc-space-between {
  justify-content: space-between;
}
.vertically_center_elements {
  display: flex;
  align-items: center;
}
.jc-right {
  justify-content: right;
}
.fa {
  font-weight: normal;
}
.text-bold {
  font-weight: bold;
}
@media screen and (min-height: 900px) {
  .signup-responsive-layout .account-right-section-signup {
    position: absolute;
    bottom: 0;
    height: calc(100vh - 72px);
    right: 0;
  }
  .signup-responsive-layout .footer {
    position: absolute;
    bottom: 0;
  }
}
@media screen and (max-height: 900px) {
  .signup-responsive-layout .account-right-section-signup {
    position: absolute;
    height: 100%;
    right: 0;
  }
  .signup-responsive-layout .account-right-section-signup {
    right: 0;
  }
}
.ant-popover-message > .anticon {
  top: 7px !important;
}
@media (max-width: 500px) {
  .form_center {
    padding: 0;
  }
  .dashboard_layout_content {
    margin: 1.5rem 1rem;
  }
  .filter_button {
    width: 100%;
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px 8px;
  }
  .ant-dropdown-placement-bottomLeft {
    min-width: 55px;
    left: 145px;
    top: 294px;
  }
}
.ant-layout {
  background: #f0f2f5 !important;
}
.ant-table-column-sorters,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px 4px;
}
.ant-card-head-title {
  white-space: normal;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
  margin: -4px -4px -4px 33px;
}
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
h1 {
  font-size: 38px;
  line-height: 46px;
}
h2 {
  font-size: 30px;
  line-height: 38px;
}
h3 {
  font-size: 24px;
  line-height: 32px;
}
h4 {
  font-size: 20px;
  line-height: 28px;
}
h5 {
  font-size: 16px;
  line-height: 24px;
}
h6 {
  font-size: 14px;
  line-height: 22px;
}
.m-auto {
  margin: 0 auto!important;
}
.mt-4 {
  margin-top: 0.25rem !important;
}
.mr-4 {
  margin-right: 0.25rem !important;
}
.mb-4 {
  margin-bottom: 0.25rem !important;
}
.ml-4 {
  margin-left: 0.25rem !important;
}
.m-4 {
  margin: 0.25rem !important;
}
.mt-8 {
  margin-top: 0.5rem !important;
}
.mr-8 {
  margin-right: 0.5rem !important;
}
.mb-8 {
  margin-bottom: 0.5rem !important;
}
.ml-8 {
  margin-left: 0.5rem !important;
}
.m-8 {
  margin: 0.5rem !important;
}
.mt-16 {
  margin-top: 1rem !important;
}
.mr-16 {
  margin-right: 1rem !important;
}
.mb-0 {
  margin-bottom: 0!important;
}
.mb-16 {
  margin-bottom: 1rem !important;
}
.ml-16 {
  margin-left: 1rem !important;
}
.m-16 {
  margin: 1rem !important;
}
.m16-24 {
  margin: 1rem 1.5rem;
}
.mt-24 {
  margin-top: 1.5rem !important;
}
.mr-24 {
  margin-right: 1.5rem !important;
}
.mb-24 {
  margin-bottom: 1.5rem !important;
}
.ml-24 {
  margin-left: 1.5rem !important;
}
.m-24 {
  margin: 1.5rem !important;
}
.mt-32 {
  margin-top: 2rem !important;
}
.mr-32 {
  margin-right: 2rem !important;
}
.mb-32 {
  margin-bottom: 2rem !important;
}
.ml-32 {
  margin-left: 2rem !important;
}
.m-32 {
  margin: 2rem !important;
}
.mt-40 {
  margin-top: 2.5rem !important;
}
.mr-40 {
  margin-right: 2.5rem !important;
}
.mb-40 {
  margin-bottom: 2.5rem !important;
}
.ml-40 {
  margin-left: 2.5rem !important;
}
.m-40 {
  margin: 2.5rem !important;
}
.mt-48 {
  margin-top: 3rem !important;
}
.mr-48 {
  margin-right: 3rem !important;
}
.mb-48 {
  margin-bottom: 3rem !important;
}
.ml-48 {
  margin-left: 3rem !important;
}
.m-48 {
  margin: 3rem !important;
}
.mt-56 {
  margin-top: 3.5rem !important;
}
.mr-56 {
  margin-right: 3.5rem !important;
}
.mb-56 {
  margin-bottom: 3.5rem !important;
}
.ml-56 {
  margin-left: 3.5rem !important;
}
.m-56 {
  margin: 3.5rem !important;
}
.mt-64 {
  margin-top: 4rem !important;
}
.mr-64 {
  margin-right: 4rem !important;
}
.mb-64 {
  margin-bottom: 4rem !important;
}
.ml-64 {
  margin-left: 4rem !important;
}
.m-64 {
  margin: 4rem !important;
}
.pt-4 {
  padding-top: 0.25rem !important;
}
.pr-4 {
  padding-right: 0.25rem !important;
}
.pb-4 {
  padding-bottom: 0.25rem !important;
}
.pl-4 {
  padding-left: 0.25rem !important;
}
.p-4 {
  padding: 0.25rem !important;
}
.pt-8 {
  padding-top: 0.5rem !important;
}
.pr-8 {
  padding-right: 0.5rem !important;
}
.pb-8 {
  padding-bottom: 0.5rem !important;
}
.pl-8 {
  padding-left: 0.5rem !important;
}
.p-8 {
  padding: 0.5rem !important;
}
.p12-24 {
  padding: 0.75rem 1.5rem !important;
}
.pt-16 {
  padding-top: 1rem !important;
}
.pr-16 {
  padding-right: 1rem !important;
}
.pb-16 {
  padding-bottom: 1rem !important;
}
.pl-16 {
  padding-left: 1rem !important;
}
.p-16 {
  padding: 1rem !important;
}
.p16-24 {
  padding: 1rem 1.5rem !important;
}
.pt-24 {
  padding-top: 1.5rem !important;
}
.pr-24 {
  padding-right: 1.5rem !important;
}
.pb-24 {
  padding-bottom: 1.5rem !important;
}
.pl-24 {
  padding-left: 1.5rem !important;
}
.p-24 {
  padding: 1.5rem !important;
}
.pt-32 {
  padding-top: 2rem !important;
}
.pr-32 {
  padding-right: 2rem !important;
}
.pb-32 {
  padding-bottom: 2rem !important;
}
.pl-32 {
  padding-left: 2rem !important;
}
.p-32 {
  padding: 2rem !important;
}
.pt-40 {
  padding-top: 2.5rem !important;
}
.pr-40 {
  padding-right: 2.5rem !important;
}
.pb-40 {
  padding-bottom: 2.5rem !important;
}
.pl-40 {
  padding-left: 2.5rem !important;
}
.p-40 {
  padding: 2.5rem !important;
}
.pt-48 {
  padding-top: 3rem !important;
}
.pr-48 {
  padding-right: 3rem !important;
}
.pb-48 {
  padding-bottom: 3rem !important;
}
.pl-48 {
  padding-left: 3rem !important;
}
.p-48 {
  padding: 3rem !important;
}
.pt-56 {
  padding-top: 3.5rem !important;
}
.pr-56 {
  padding-right: 3.5rem !important;
}
.pb-56 {
  padding-bottom: 3.5rem !important;
}
.pl-56 {
  padding-left: 3.5rem !important;
}
.p-56 {
  padding: 3.5rem !important;
}
.pt-64 {
  padding-top: 4rem !important;
}
.pr-64 {
  padding-right: 4rem !important;
}
.pb-64 {
  padding-bottom: 4rem !important;
}
.pl-64 {
  padding-left: 4rem !important;
}
.p-64 {
  padding: 4rem !important;
}
.primary-color {
  color: #da182b;
}
.color-gray-1 {
  color: #FFFFFF;
}
.color-gray-2 {
  color: #F9FAFC;
}
.color-gray-3 {
  color: #F2F5F7;
}
.color-gray-4 {
  color: #E6EBF0;
}
.color-gray-5 {
  color: #D3D9E0;
}
.color-gray-6 {
  color: #ADB5C0 !important;
}
.color-gray-7 {
  color: #858C96;
}
.color-gray-8 {
  color: #545B68;
}
.color-gray-9 {
  color: #27292C;
}
.color-gray-10 {
  color: #000000;
}
.color-green-1 {
  color: #E4FBD6;
}
.color-green-2 {
  color: #C3F8AF;
}
.color-green-3 {
  color: #97EB84;
}
.color-green-4 {
  color: #6DD861;
}
.color-green-5 {
  color: #35BF33;
}
.color-green-6 {
  color: #25A42E;
}
.color-green-7 {
  color: #19892B;
}
.color-green-8 {
  color: #106E27;
}
.color-green-9 {
  color: #106E27;
}
.color-green-10 {
  color: #095B24;
}
.color-red-6 {
  color: #B11C21;
}
.color-blue-6 {
  color: #1B2977;
}
.color-yellow-7 {
  color: #D4B106;
}
.color-success {
  color: #23af47;
}
.logo {
  display: flex;
  align-items: center;
}
.table-header-sm th {
  font-size: 12px;
}

